import React from "react"
import Layout from "../components/Layout"
import Block from "../components/ui/Block"
import FormGroup from "../components/ui/FormGroup"

export default function Sportarten() {
  const pageContext = {
    seo: {
      title: "Turnverein Lahr",
      description:
        "Der Turnverein Lahr von 1846 e.V. zählt zu den ältesten Turnvereinen in Baden- Württemberg und ist mit nahezu 1700 Mitgliedern der größte Sport anbietende Verein in Lahr.",
      robots: ["index, follow"],
      path: "/kontakt",
      lang: "de",
    },
  }
  return (
    <Layout pageContext={pageContext}>
      <Block
        note="Kontakt"
        title="Hier können Sie ganz einfach Kontakt zu uns aufnehmen"
        content="Nehmen Sie gerne per Mail oder telefonisch Kontakt zu uns auf, wir versuchen Ihre Anfrage schnellstmöglich zu bearbeiten."
        textCenter
      />
      <form
        className="row main-container"
        action="https://submit-form.com/jPqKXlzD"
        style={{ maxWidth: "580px" }}
      >
        <input
          type="hidden"
          name="_redirect"
          value="https://www.turnverein-lahr.de/email-gesendet"
        />
        <div className="col-xs-12 col-md-6">
          <FormGroup
            type="input"
            label="Vorname"
            name="vorname"
            placeholder="Vorname"
            id="filter-all"
          />
        </div>
        <div className="col-xs-12 col-md-6">
          <FormGroup
            type="input"
            label="Nachname"
            name="nachname"
            placeholder="Nachname"
            id="filter-all"
          />
        </div>
        <div className="col-xs-12">
          <FormGroup
            type="mail"
            label="E-Mail-Adresse"
            name="mail"
            placeholder="max@mustermann.de"
            id="filter-all"
            required
          />
        </div>
        <div className="col-xs-12">
          <FormGroup
            type="phone"
            label="Telefonnummer"
            name="telefon"
            placeholder="+49..."
            id="filter-all"
          />
        </div>
        <div className="col-xs-12">
          <FormGroup
            type="textarea"
            label="Nachricht"
            name="nachricht"
            placeholder="Wie können wir weiterhelfen?"
            id="filter-all"
            required
          />
        </div>
        <div style={{ display: "flex", gap: "10px", margin: "16px 0 24px 0" }}>
          <input
            type="checkbox"
            value="accept"
            name="agb"
            required
            style={{ float: "left" }}
          />
          <label for="agb" className="text-paragraph-small">
            Ich akzeptiere die AGB und Datenschutzbestimmungen.
          </label>
        </div>
        <input
          type="submit"
          className="wis-btn wis-btn-primary"
          value="Nachricht senden"
          style={{ width: "100%" }}
        ></input>
      </form>
    </Layout>
  )
}
